<template>
  <div id="contact">
    <div class="wrapper">
      <h2 data-aos="fade-right">Got a question about Cloeren Aerospace?</h2>
      <p data-aos="fade-right">
        Please fill out the form below to contact us and we will get back to you
        soon.
      </p>
      <div class="form" data-aos="fade-left">
        <input
          type="text"
          class="url"
          v-model="form.url"
          :class="{ error: form.errors.has('url') }"
          placeholder="Enter URL"
        />
        <input
          type="text"
          v-model="form.firstName"
          placeholder="First Name*"
          :class="{ error: form.errors.has('firstName') }"
          @keyup="onKeyup('firstName')"
          required
        />
        <input
          type="text"
          v-model="form.lastName"
          placeholder="Last Name*"
          :class="{ error: form.errors.has('lastName') }"
          @keyup="onKeyup('lastName')"
          required
        />
        <input
          type="text"
          v-model="form.email"
          placeholder="E-mail Address*"
          :class="{ error: form.errors.has('email') }"
          @keyup="onKeyup('email')"
          required
        />
        <input type="text" v-model="form.company" placeholder="Company" />
        <textarea
          cols="40"
          rows="10"
          placeholder="Enter Your Message*"
          :class="{ error: form.errors.has('message') }"
          @keyup="onKeyup('message')"
          v-model="form.message"
          required
        ></textarea>
        <button class="button" @click="send">
          <Transition name="fade">
            <div class="spinner" v-if="sending">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </Transition>
          <Transition name="fade">
            <div class="inner" v-if="!sending">
              <div>Send Message</div>
              <svg
                class="icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 8.59 16"
              >
                <path
                  d="m.55,16c-.15,0-.27-.05-.38-.16s-.16-.23-.16-.38.05-.27.16-.38l7.15-7.15L.27.92c-.06-.07-.11-.16-.13-.25s-.03-.19,0-.27.07-.17.13-.24c.1-.1.23-.16.38-.16s.28.05.38.16l7.4,7.41c.05.05.09.11.12.18s.04.14.04.2-.01.13-.04.2-.07.12-.12.17L.92,15.84c-.05.05-.11.09-.18.12s-.13.04-.2.04Z"
                />
              </svg>
            </div>
          </Transition>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Form from '../utilities/Form';
import axios from 'axios';

import 'sweetalert2/dist/sweetalert2.min.css';

export default {
  data() {
    return {
      form: new Form({
        firstName: null,
        lastName: null,
        email: null,
        company: null,
        message: null,
        url: null,
      }),
      sending: false,
    };
  },
  methods: {
    validate() {
      let errors = [];

      if (!this.validEmail(this.form.email)) {
        errors.push('email');
      }

      if (!this.form.firstName) {
        errors.push('firstName');
      }

      if (!this.form.lastName) {
        errors.push('lastName');
      }

      if (!this.form.message) {
        errors.push('message');
      }

      this.form.errors.record(errors);
    },

    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    onKeyup(field) {
      if (!this.form.errors.has(field)) {
        return;
      }

      if (field === 'email') {
        if (this.validEmail(this.form.email)) {
          this.form.errors.clear('email');
        }
      }

      if (['lastName', 'firstName', 'message'].includes(field)) {
        if (this.form[field]) {
          this.form.errors.clear(field);
        }
      }
    },

    async send() {
      if (this.sending) {
        return;
      }

      if (this.form.url) {
        this.showAlert();
      }

      this.validate();

      if (!this.form.errors.any()) {
        this.sending = true;

        const response = await axios.post('/mail.php', {
          first_name: this.form.firstName,
          last_name: this.form.lastName,
          email: this.form.email,
          company: this.form.company,
          message: this.form.message,
          url: this.form.url,
        });

        if (parseInt(response.data) === 1) {
          this.onSuccess();

          return;
        }

        this.onFail();
      }
    },
    showAlert(
      message = 'There was a problem sending your message. Please try again.',
      success = false
    ) {
      this.$swal({
        title: success ? 'Message Sent' : 'Unable to Send',
        text: message,
        icon: success ? 'success' : 'error',
        confirmButtonColor: '#3a5fb0',
        color: 'white',
        background: '#14213d',
        iconColor: success ? null : '#ea2828',
      });
    },
    onFail() {
      this.sending = false;

      this.showAlert();
    },
    onSuccess() {
      this.sending = false;

      this.form.reset();

      this.showAlert(
        'A Cloeren Aerospace representative will contact you as soon as possible!',
        true
      );
    },
  },
};
</script>

<style lang="scss" scoped>
#contact {
  padding-top: 150px;
  padding-bottom: 150px;
  padding-left: 30px;
  padding-right: 30px;
  .wrapper {
    flex-direction: column;
    padding-left: 5%;
    padding-right: 5%;
    @include breakpoint(sm-max) {
      padding-left: 0;
      padding-right: 0;
    }
    h2 {
      margin-top: 0;
      margin-bottom: 10px;
      font-size: 40px;
    }
    p {
      margin-top: 0;
      margin-bottom: 40px;
    }
    .form {
      display: flex;
      flex-direction: column;
      input,
      textarea {
        font-family: $font;
        padding-left: 20px;
        padding-right: 20px;
        background: white;
        font-size: 15px;
        color: $body;
        border: 1px solid #d2d2d2;
        font-weight: bold;
        margin-bottom: 15px;
        transition: border 0.25s ease-in-out;
        outline: 0;
        border-radius: 0;
        &.error {
          border-color: red;
        }
      }
      input {
        height: 70px;
        &.url {
          display: none !important;
          visibility: hidden !important;
        }
      }
      textarea {
        padding-top: 25px;
        padding-bottom: 25px;
        height: 150px;
      }
      button.button {
        align-self: flex-start;
        padding: 0;
        width: 200px;
        height: 54px;
        .inner {
          padding: 10px 25px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 100%;
        }
        .spinner {
          display: flex;
          position: relative;
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: center;
          div {
            display: block;
            position: absolute;
            width: 24px;
            height: 24px;
            margin: 2px;
            border-width: 2px;
            border-style: solid;
            border-radius: 50%;
            animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: white transparent transparent transparent;
            &:nth-child(1) {
              animation-delay: -0.45s;
            }
            &:nth-child(2) {
              animation-delay: -0.3s;
            }
            &:nth-child(3) {
              animation-delay: -0.15s;
            }
          }
        }
      }
    }
  }
}

// Sweet Alert
div:where(.swal2-container) {
  .swal2-html-container {
    color: #7d8391;
    margin: 1em 2.6em 0.3em;
  }
  button:where(.swal2-styled).swal2-confirm {
    padding: 10px 25px;
    font-weight: bold;
    font-size: 14px;
  }
}

// Animations
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  position: absolute;
  transition: opacity 0.25s ease-in-out;
  top: 0;
  left: 0;
}
// .fade-enter-from,
// .fade-leave-to {
//   opacity: 0;
// }
</style>
