<template>
  <div id="capabilities">
    <div class="wrapper">
      <div data-aos="fade-left" data-aos-duration="300">
        <h1>
          We have a wide range of capabilities available to meet your individual
          requirements
        </h1>
        <p>
          Our overall capabilities include 3, 4, and 5-axis Milling, 5-axis
          capacity up to 10-meters, CNC Lathe, 9-axis Mill-Turn, EDM Wire and
          Sinker, Waterjet Cutting, Laser Cutting, Laser Welding, Abrasive
          Cutting, and CMM Inspection Systems, Optical Comparators, and Assembly
          Services.
        </p>
        <a class="button" href="/Equipment-List.pdf" target="_blank"
          ><div>View Equipment List</div>
          <svg
            class="icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 8.59 16"
          >
            <path
              d="m.55,16c-.15,0-.27-.05-.38-.16s-.16-.23-.16-.38.05-.27.16-.38l7.15-7.15L.27.92c-.06-.07-.11-.16-.13-.25s-.03-.19,0-.27.07-.17.13-.24c.1-.1.23-.16.38-.16s.28.05.38.16l7.4,7.41c.05.05.09.11.12.18s.04.14.04.2-.01.13-.04.2-.07.12-.12.17L.92,15.84c-.05.05-.11.09-.18.12s-.13.04-.2.04Z"
            /></svg
        ></a>
      </div>
      <div
        v-for="galleryImage in gallery"
        :key="galleryImage"
        data-aos="fade-left"
        :data-aos-duration="galleryImage.duration"
      >
        <div
          class="image"
          :style="`background-image: url('${require(`@/assets/images/${galleryImage.image}`)}')`"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import gallery from '@/assets/data/gallery';

export default {
  data() {
    return {
      gallery,
    };
  },
};
</script>

<style lang="scss" scoped>
#capabilities {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 70px;
  padding-bottom: 140px;
  .wrapper {
    padding-right: 5%;
    padding-left: 5%;
    @include breakpoint(sm-max) {
      padding-left: 0;
      padding-right: 0;
    }
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-auto-rows: 1fr;
    gap: 20px;
    > div {
      &:first-child {
        display: flex;
        flex-direction: column;
        @include breakpoint(sm-max) {
          grid-column: 1 / -1;
        }
      }
      h1 {
        font-size: 24px;
        color: $primary;
        line-height: 1.2;
        margin-top: 0;
        margin-bottom: 0.8em;
      }
      p {
        margin: 0 0 1.5em;
      }
      .image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        width: 100%;
        height: 100%;
      }
      .button {
        align-self: flex-start;
        margin-bottom: 2em;
        .icon {
          margin-left: 3em;
        }
      }
    }
  }
}
</style>
