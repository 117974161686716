<template>
  <div id="brands">
    <div class="wrapper">
      <h2 data-aos="fade-right">Machine Brands Include:</h2>
      <div>
        <div
          data-aos="fade-left"
          :data-aos-duration="((index % 4) + 1) * 200"
          class="brand"
          v-for="(brand, index) in brands"
          :key="brand"
        >
          <a :href="brand.url" :title="brand.title" target="_blank">
            <img :src="require(`@/assets/images/${brand.image}`)" alt="" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import brands from '@/assets/data/brands';

export default {
  data() {
    return {
      brands,
    };
  },
};
</script>

<style lang="scss" scoped>
#brands {
  padding-top: 70px;
  padding-left: 30px;
  padding-right: 30px;
  .wrapper {
    padding-left: 5%;
    padding-right: 5%;
    flex-direction: column;
    @include breakpoint(sm-max) {
      padding-left: 0;
      padding-right: 0;
    }
    h2 {
      margin: 0 10px;
      font-size: 26px;
      line-height: 1.4;
    }
    > div {
      margin-top: 30px;
      margin-bottom: 100px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      grid-auto-rows: 1fr;
      gap: 20px;
      @include breakpoint(sm-max) {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      }
      .brand {
        > a {
          background: #efefef;
          padding: 2em;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 100%;
          transition: opacity 0.25s ease-in-out;
          @include hover {
            opacity: 0.6;
          }
        }
        img {
          max-height: 110px;
          @include breakpoint(sm-max) {
            max-height: 90px;
          }
        }
      }
    }
  }
}
</style>
