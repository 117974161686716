<template>
  <div id="hero">
    <div class="title">
      <h1 data-aos="fade-up">Cloeren</h1>
      <h2 data-aos="fade-up" data-aos-duration="600">Aerospace</h2>
    </div>
    <div class="description" data-aos="fade-up">
      <p>
        Cloeren Aerospace headquartered in Orange, Texas, is a family owned and
        operated company with three generations of engineering and manufacturing
        experience. Our state-of-the-art machinery, trained and experienced
        staff, and best-in-class CMM inspection systems ensure the best results
        for your Aerospace needs.
      </p>
    </div>
  </div>
  <div id="subhero">
    <div class="wrapper" data-aos="fade-down" data-aos-duration="600">
      <div class="video">
        <video
          controls
          disablePictureInPicture
          loop
          poster="@/assets/images/aerospace-video-placeholder.jpg"
          playsinline
          preload="auto"
        >
          <source
            src="@/assets/videos/Cloeren-Aerospace.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <div class="description">
        <h2>
          With over 40 years of experience in the manufacture of equipment for
          plastic extrusion, Cloeren has developed an extensive array of metal
          machine tools and expertise in the tight tolerance machining required
          for the aerospace industry. Cloeren is dedicated to providing the
          highest quality Products for our customers, delivered on time and with
          all required quality data.
        </h2>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#hero {
  width: 100%;
  min-height: 100vh;
  background: $primary;
  background-image: url('@/assets/images/aerospace-astronaut.jpg');
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  color: white;
  padding-right: 30px;
  padding-left: 30px;
  @include breakpoint(md-max) {
    min-height: 50vh;
  }
  @include breakpoint(xs-max) {
    background-size: 150%;
  }
  .title {
    width: 25%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;
    @include breakpoint(lg-max) {
      width: 30%;
    }
    @include breakpoint(md-max) {
      align-items: center;
      width: 100%;
    }
    h1,
    h2 {
      font-size: 75px;
      margin: 0;
      line-height: 1.1;
      @include breakpoint(1400px, max) {
        font-size: 60px;
      }
      @include breakpoint(sm-max) {
        font-size: 50px;
      }
    }
    h2 {
      font-weight: 300;
    }
  }
  .description {
    width: 25%;
    display: flex;
    align-items: center;
    @include breakpoint(md-max) {
      display: none;
    }
    p {
      margin-top: 0;
      margin-bottom: 0;
      @include breakpoint(1400px, min) {
        margin-right: 38.4%;
      }
      @include breakpoint(lg-max) {
        font-size: 14px;
      }
    }
  }
}
#subhero {
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  justify-content: center;
  margin-top: -175px;
  padding-bottom: 100px;
  @include breakpoint(lg-max) {
    margin-top: -150px;
  }
  @include breakpoint(sm-max) {
    margin-top: -100px;
  }
  .wrapper {
    box-shadow: 0px 30px 68px 0px rgba(0, 0, 0, 0.1);
    @include breakpoint(md-max) {
      flex-direction: column;
    }
    .video {
      width: 50%;
      @include breakpoint(md-max) {
        width: 100%;
      }
      background: black;
      video {
        width: 100%;
        height: auto;
        object-fit: cover;
        display: block;
      }
    }
    .description {
      width: 50%;
      background: white;
      padding: 3em;
      @include breakpoint(md-max) {
        width: 100%;
      }
      @include breakpoint(1400px, min) {
        padding: 4em;
      }
      h2 {
        margin: 0;
        font-size: 16px;
        line-height: 1.5;
        @include breakpoint(1100px, min) {
          font-size: 18px;
        }
        @include breakpoint(1400px, min) {
          font-size: 22px;
        }
      }
    }
  }
}
</style>
