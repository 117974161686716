<template>
  <div id="experience">
    <div class="wrapper">
      <div class="title" data-aos="fade-down">
        <h1>Experience</h1>
      </div>
      <div class="spacer"></div>
      <div class="description" data-aos="fade-up">
        <h1>
          We specialize and have extensive experience in a variety of metals
        </h1>
        <p>
          Not limited to Stainless Steel, Nitronic 60, Nickel-alloys, Super
          Alloys, Titanium's, Tool Steel, Aluminum, Copper alloys, and more.
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#experience {
  padding-top: 70px;
  padding-bottom: 70px;
  .wrapper {
    background-image: url('@/assets/images/aerospace-experience.jpg');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    flex-direction: column;
    .title {
      color: $primary;
      background: white;
      padding: 20px 30px;
      align-self: flex-start;
      h1 {
        margin: 0;
        font-size: 32px;
        line-height: 1.2;
      }
    }
    .spacer {
      padding: 15% 0;
      @include breakpoint(sm-max) {
        padding-bottom: 30%;
        padding-top: 30%;
      }
    }
    .description {
      align-self: flex-end;
      width: 50%;
      background: white;
      padding: 2em;
      @include breakpoint(sm-max) {
        width: calc(100% - 60px);
        margin-left: auto;
        margin-right: auto;
      }
      h1 {
        color: $primary;
        margin-top: 0;
        margin-bottom: 10px;
        font-size: 22px;
        line-height: 1.2;
      }
      p {
        margin: 0;
      }
    }
  }
}
</style>
