class Errors {
  /**
   * Create a new Errors instance.
   */
  constructor() {
    this.errors = [];
  }

  /**
   * Determine if any errors exists for the given field.
   *
   * @param  {string} field
   */
  has(field) {
    return field === null ? true : this.errors.includes(field);
  }

  /**
   * Record the new errors.
   *
   * @param  {object} errors
   */
  record(errors) {
    this.errors = errors;
  }

  any() {
    return this.errors.length > 0;
  }

  /**
   * Clear one or all error fields.
   *
   * @param  {string|null} field
   */
  clear(field) {
    if (field) {
      const index = this.errors.indexOf(field);

      if (index !== -1) {
        this.errors.splice(index, 1);
      }

      return;
    }

    this.errors = [];
  }
}

export default Errors;
