<template>
  <header id="navigation">
    <router-link class="logo" :to="{ name: 'home' }">
      <img src="@/assets/images/logo.png" alt="" />
    </router-link>
    <a
      class="hamburger"
      aria-hidden="true"
      href="#"
      @click.prevent="toggleMenu"
    >
      <div></div>
      <div></div>
      <div></div>
    </a>
    <ul class="menu">
      <li>
        <a href="#" @click.prevent="scrollTo('#capabilities')">Capabilities</a>
      </li>
      <li><a href="#" @click.prevent="scrollTo('#brands')">Brands</a></li>
      <li><a href="#" @click.prevent="scrollTo('#contact')">Contact</a></li>
    </ul>
  </header>
  <Transition name="fade">
    <div class="mobile-menu-bg" v-show="mobileMenu">
      <ul class="menu">
        <li>
          <a href="#" @click.prevent="scrollTo('#capabilities')"
            >Capabilities</a
          >
        </li>
        <li><a href="#" @click.prevent="scrollTo('#brands')">Brands</a></li>
        <li><a href="#" @click.prevent="scrollTo('#contact')">Contact</a></li>
      </ul>
    </div>
  </Transition>
</template>

<script>
export default {
  data() {
    return {
      mobileMenu: false,
    };
  },
  mounted() {
    document.body.classList.remove('mobile-menu');
  },
  methods: {
    async scrollTo(element) {
      if (this.mobileMenu) {
        this.toggleMenu();
      }
      if (this.$route.name !== 'home') {
        await this.$router.push({ name: 'home' });
      }

      this.$scrollTo(element);
    },
    toggleMenu() {
      this.mobileMenu = !this.mobileMenu;

      if (this.mobileMenu) {
        document.body.classList.add('mobile-menu');
      } else {
        document.body.classList.remove('mobile-menu');
      }
    },
  },
};
</script>

<style lang="scss">
#navigation {
  height: 116px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  padding: 1.8em 1em 1.8em 1.5em;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  transition: all 0.4s ease;
  .logo {
    display: flex;
    align-items: center;
    img {
      width: 250px;
      @include breakpoint(sm-max) {
        width: 200px;
      }
    }
  }
  .hamburger {
    display: none;
    cursor: pointer;
    justify-content: space-between;
    flex-direction: column;
    box-sizing: content-box;
    padding-left: 15px;
    padding-right: 15px;
    height: 18px;
    width: 30px;
    line-height: 1;
    @include hover {
      div {
        background: $accent;
      }
    }
    @include breakpoint(md-max) {
      display: flex;
    }
    div {
      width: 30px;
      height: 2px;
      background: white;
      opacity: 1;
      transform: rotate(0);
      transition: 0.25s ease-in-out;
      &:nth-child(1) {
        transform-origin: left top;
      }
      &:nth-child(3) {
        transform-origin: left bottom;
      }
    }
  }
  .menu {
    display: flex;
    padding: 0;
    align-items: center;
    list-style-type: none;
    overflow: hidden;
    @include breakpoint(md-max) {
      display: none;
    }
    li {
      padding-left: 2em;
      padding-right: 2em;
      a {
        display: block;
        color: white;
        font-weight: bold;
        font-size: 16px;
        // transition: color 0.25s ease-in-out;
        @include hover {
          // opacity: 0.8;
          color: $accent;
        }
      }
    }
  }
}
body.mobile-menu #navigation {
  position: fixed;
  .hamburger div {
    &:nth-child(1) {
      transform: rotate(45deg);
      width: 24px;
    }
    &:nth-child(2) {
      width: 0;
      opacity: 0;
    }
    &:nth-child(3) {
      width: 24px;
      transform: rotate(-45deg);
    }
  }
}
.mobile-menu-bg {
  background: rgba($primary, 0.98);
  height: 100vh;
  width: 100vh;
  position: fixed;
  z-index: 1;
  display: flex;
  align-items: center;
  .menu {
    list-style-type: none;
    li {
      margin-bottom: 20px;
      @include breakpoint(md-max) {
        margin-bottom: 10px;
      }
      a {
        font-size: 60px;
        color: white;
        transition: color 0.25s ease-in-out;
        font-weight: bold;
        @include hover {
          color: $accent;
        }
        @include breakpoint(sm-max) {
          font-size: 50px;
        }
      }
    }
  }
}
</style>
