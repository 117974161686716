export default [
  {image: 'DMG-MORI.png', title: 'DMG MORI', url: 'https://dmgmori.com/'},
  {image: 'Okuma.png', title: 'Okuma', url: 'https://okuma.com/'},
  {image: 'Mitsubishi-Motors.png', title: 'Mitsubishi Motors', url: 'https://www.mcmachinery.com/'},
  {image: 'Soraluce.png', title: 'Soraluce', url: 'https://soraluce.com/'},
  {image: 'Toyoda.png', title: 'Toyoda', url: 'https://jtektmachinery.com/'},
  {image: 'Omax.png', title: 'Omax', url: 'https://omax.com/'},
  {image: 'Flow.png', title: 'Flow', url: 'https://midatlanticmachinery.com/'},
  {image: 'Hexagon.png', title: 'Hexagon', url: 'https://hexagon.com/'},
];
