<template>
  <div class="not-found">
    <div class="content">
      <h1>404</h1>
      <h5>We can't seem to find the page you were looking for.</h5>
      <p>Try searching or go back to our home page.</p>
      <router-link :to="{ name: 'home' }">
        <div>Back to Home</div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.59 16">
          <path
            d="m.55,16c-.15,0-.27-.05-.38-.16s-.16-.23-.16-.38.05-.27.16-.38l7.15-7.15L.27.92c-.06-.07-.11-.16-.13-.25s-.03-.19,0-.27.07-.17.13-.24c.1-.1.23-.16.38-.16s.28.05.38.16l7.4,7.41c.05.05.09.11.12.18s.04.14.04.2-.01.13-.04.2-.07.12-.12.17L.92,15.84c-.05.05-.11.09-.18.12s-.13.04-.2.04Z"
          />
        </svg>
      </router-link>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.not-found {
  width: 100vw;
  height: 100vh;
  background: $primary url('@/assets/images/aerospace-astronaut.jpg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  .content {
    background: rgba($primary, 0.5);
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    text-align: center;
    padding: 30px;
    h1 {
      font-size: 180px;
      margin: 0 0 10px;
      line-height: 1;
      @include breakpoint(sm-max) {
        font-size: 140px;
    }
    }
    h5,
    p {
      font-size: 16px;
      line-height: 1.5;
      margin: 0;
    }
    a {
      height: 54px;
      font-family: $font;
      padding: 10px 25px;
      font-size: 14px;
      color: white;
      fill: white;
      border: 1px solid white;
      font-weight: bold;
      transition: all 0.25s ease-in-out;
      outline: 0;
      display: flex;
      align-items: center;
      margin-top: 30px;
      svg {
        margin-left: 60px;
        height: 16px;
      }
      @include hover {
        background-color: $accent;
        border-color: $accent;
      }
    }
  }
}
</style>
