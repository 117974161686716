<template>
  <hero></hero>
  <capabilities></capabilities>
  <banner></banner>
  <div style="background: white; padding-top: 70px">
    <experience></experience>
    <brands></brands>
  </div>
  <contact></contact>
  <footer id="footer">
    <div class="wrapper">
      <p>&copy; 2023 Cloeren Aerospace. All rights reserved.</p>
      <div class="social">
        <a
          href="https://www.facebook.com/cloerenaerospace/"
          target="_blank"
          title="Facebook"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.19 12">
            <path
              d="m1.8,6.63H0v-2.13h1.8v-1.69c0-.89.25-1.58.75-2.07s1.16-.74,1.99-.74c.67,0,1.22.03,1.64.09v1.9h-1.12c-.42,0-.71.09-.87.28-.12.16-.19.41-.19.75v1.48h1.99l-.28,2.13h-1.71v5.37H1.8v-5.37Z"
            />
          </svg>
        </a>
        <a
          href="https://www.instagram.com/cloeren_aerospace/"
          target="_blank"
          title="Instagram"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.5 10.5">
            <path
              d="m10.48,3.07c.02.44.02,1.16.02,2.18s-.01,1.75-.04,2.19-.09.83-.2,1.16c-.14.39-.36.73-.64,1.02s-.63.5-1.02.64c-.33.11-.71.18-1.16.2s-1.18.04-2.19.04-1.75-.01-2.19-.04-.83-.1-1.16-.22c-.39-.12-.73-.33-1.02-.62s-.5-.63-.64-1.02c-.11-.33-.18-.71-.2-1.16s-.04-1.18-.04-2.19.01-1.75.04-2.19.09-.83.2-1.16c.14-.39.36-.73.64-1.02S1.51.38,1.9.23c.33-.11.71-.18,1.16-.2s1.18-.04,2.19-.04,1.75.01,2.19.04.83.09,1.16.2c.39.14.73.36,1.02.64s.5.63.64,1.02c.11.33.18.72.21,1.17Zm-1.12,5.27c.09-.27.16-.69.19-1.27.02-.34.02-.83.02-1.45v-.75c0-.64,0-1.12-.02-1.45-.03-.59-.09-1.02-.19-1.27-.19-.48-.52-.82-1.01-1.01-.25-.09-.67-.16-1.27-.19-.34-.02-.83-.02-1.45-.02h-.75c-.62,0-1.11,0-1.45.02-.58.03-1,.09-1.27.19-.48.19-.82.52-1.01,1.01-.09.25-.16.67-.19,1.27-.02.34-.02.83-.02,1.45v.75c0,.62,0,1.11.02,1.45.03.58.09,1,.19,1.27.2.48.54.82,1.01,1.01.27.09.69.16,1.27.19.34.02.83.02,1.45.02h.75c.64,0,1.12,0,1.45-.02.59-.03,1.02-.09,1.27-.19.48-.2.82-.54,1.01-1.01ZM5.25,2.55c.48,0,.93.12,1.35.36s.74.57.98.98.36.86.36,1.35-.12.93-.36,1.35-.57.74-.98.98-.86.36-1.35.36-.93-.12-1.35-.36-.74-.57-.98-.98-.36-.86-.36-1.35.12-.93.36-1.35.57-.74.98-.98.86-.36,1.35-.36Zm0,4.45c.48,0,.9-.17,1.24-.52s.52-.76.52-1.24-.17-.9-.52-1.24-.76-.52-1.24-.52-.9.17-1.24.52-.52.76-.52,1.24.17.9.52,1.24.76.52,1.24.52Zm3.45-4.57c0-.17-.06-.32-.19-.45s-.27-.19-.45-.19-.32.06-.45.19-.19.27-.19.45.06.32.19.45.27.19.45.19.32-.06.43-.19.18-.27.2-.45Z"
            />
          </svg>
        </a>
        <a
          href="https://www.linkedin.com/company/cloeren-aerospace/"
          target="_blank"
          title="LinkedIn"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.29 9.83">
            <path
              d="m1.23,2.29h-.01c-.74,0-1.22-.51-1.22-1.15S.5,0,1.25,0s1.23.5,1.23,1.15c0,.64-.48,1.15-1.25,1.15Zm1.11,7.54H.13V3.2h2.21v6.64Zm7.95,0h-2.2v-3.55c0-.89-.32-1.5-1.12-1.5-.61,0-.97.41-1.13.8-.05.15-.07.34-.07.54v3.7h-2.2c.03-6.02,0-6.64,0-6.64h2.2v.96h-.01c.29-.46.81-1.12,2-1.12,1.45,0,2.54.95,2.54,2.99v3.8Z"
            />
          </svg>
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

import Banner from '@/components/Banner';
import Brands from '@/components/Brands';
import Contact from '@/components/Contact';
import Experience from '@/components/Experience';
import Hero from '@/components/Hero';
import Capabilities from '@/components/Capabilities';

export default {
  name: 'HomeView',
  components: {
    Banner,
    Brands,
    Contact,
    Experience,
    Hero,
    Capabilities,
  },
  created() {
    AOS.init({
      once: true,
      duration: 300,
      placement: 'bottom-bottom',
    });
  },
};
</script>

<style lang="scss" scoped>
#footer {
  background: $primary;
  padding: 20px 30px;
  color: $body;
  .wrapper {
    justify-content: space-between;
    align-items: center;
    @include breakpoint(sm-max) {
      text-align: center;
      flex-direction: column;
    }
  }
  p {
    margin: 0;
    font-size: 14px;
    @include breakpoint(sm-max) {
      margin-bottom: 10px;
    }
  }
  .social {
    display: flex;
    align-items: center;
    a {
      line-height: 0;
      padding: 10px;
      @include hover {
        svg {
          fill: $accent;
        }
      }
    }
    svg {
      transition: fill 0.25s ease-in-out;
      fill: $body;
      height: 15px;
    }
  }
}
</style>
