<template>
  <router-view v-slot="{ Component, route }">
    <div :key="route.name">
      <navigation></navigation>
      <component :is="Component" />
    </div>
  </router-view>
</template>

<script>
import Navigation from '@/components/Navigation';

export default {
  name: 'App',
  components: {
    Navigation,
  },
};
</script>

<style lang="scss">
body,
html {
  min-height: 100%;
  width: 100%;
}
body {
  background: #f7f7f8;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: none;
  font-weight: 300;
  font-family: $font;
  color: $body;
}
a {
  text-decoration: none;
  color: $primary;
  transition: color 0.25s ease-in-out;
  @include hover {
    color: $accent;
  }
}
p {
  font-size: 16px;
  line-height: 1.5;
}
h2 {
  font-weight: 300;
}
img {
  border-style: none;
  vertical-align: top;
  max-width: 100%;
  height: auto;
}
* {
  box-sizing: border-box;
}
.wrapper {
  max-width: 1400px;
  display: flex;
  margin: 0 auto;
  box-sizing: border-box;
}
.button {
  background: $primary;
  border: 1px solid $primary;
  color: white;
  font-weight: bold;
  font-size: 14px;
  line-height: 32px;
  padding: 10px 25px;
  cursor: pointer;
  fill: white;
  transition: all 0.25s ease-in-out;
  display: flex;
  align-items: center;
  @include hover {
    background-color: $accent;
    border-color: $accent;
    color: white;
    fill: white;
  }
  .icon {
    height: 16px;
  }
}

// Transitions
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

// Animations
[data-aos='fade-up'] {
  transform: translate3d(0, 20px, 0);
}
[data-aos='fade-down'] {
  transform: translate3d(0, -20px, 0);
}
[data-aos='fade-left'] {
  transform: translate3d(20px, 0, 0);
}
[data-aos='fade-right'] {
  transform: translate3d(-20px, 0, 0);
}
</style>
