import { createRouter, createWebHistory } from 'vue-router';

import HomeView from '@/views/HomeView';
import NotFoundView from '@/views/NotFoundView';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFoundView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
