export default [
  {image: 'aerospace-gallery-1.jpg', duration: 600},
  {image: 'aerospace-gallery-2.jpg', duration: 900},
  {image: 'aerospace-gallery-3.jpg', duration: 300},
  {image: 'aerospace-gallery-4.jpg', duration: 600},
  {image: 'aerospace-gallery-5.jpg', duration: 900},
  {image: 'aerospace-gallery-6.jpg', duration: 300},
  {image: 'aerospace-gallery-7.jpg', duration: 600},
  {image: 'aerospace-gallery-8.jpg', duration: 900},
];
